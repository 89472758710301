<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="property-specs-nav ">
      <div class=" d-flex ">
        <a href="#features">{{ $t('features') }}</a>
        <a href="#advantages" v-if="hasAdvantages">{{ $t('advantages') }}</a>
        <a href="#location" v-if="hasLocation">{{ $t('location') }}</a>
      </div>
    </div>

  </div>

</template>

