import Button from "@/components/Common/Buttons/Button/Button";
import HeartIconOrange from "@/components/Common/Icons/HeartIconOrange";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";
import TrustedAgenciesIcon from "@/components/Common/Icons/TrustedAgenciesIcon.vue";
import PhoneCallButton from "@/components/Components/PhoneCallButton/PhoneCallButton.vue";

export default {
    name: 'RoommateAdContact',
    props: {
        date: {
            type: Date,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },
        amount: {
            type: String,
            default: ''
        },
        deposit: {
            type: String,
            default: ''
        },
        property: {
            type: Object,
            default: null
        },
        agency: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    computed: {

        formattedDate() {
            // Check if the date can be parsed correctly
            const parsedDate = new Date(this.date);
            if (isNaN(parsedDate)) {
                return 'Invalid date'; // Handle invalid date
            }
            // Format date to DD/MM/YYYY
            return new Intl.DateTimeFormat('en-GB').format(parsedDate);
        }
    },
    components: {
        PhoneCallButton,
        TrustedAgenciesIcon,
        AddToFavourites,
        Button,
        HeartIconOrange
    },

}