<script>

import Button from "@/components/Common/Buttons/Button/Button.vue";

export default {
  name: 'PhoneCallButton',
  components: {Button},
  props: {
    phone: {
      type: String
    }
  },
  data() {
    return {
      isPhoneShown: false
    }
  },
  methods: {
    showPhone() {
      this.isPhoneShown = true;
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800;
    },
  },
}
</script>
<style  lang="scss" scoped>
@import "~@/assets/css/variables.scss";
.phone-box {
  font-family: $font-bold;
  padding: 16px 50px;
  width: 100%;
  border-radius: 50px;
  font-size: 18px;
  background: $color-grey-100;
}
</style>
<template>
<div>
  <a v-bind:href="'tel:' + phone" v-if="isMobile">
    <Button class="roommate-button" :size="'medium'" :theme="'solid-grey'" :text="$t('call-us')"/>
  </a>
  <div @click="showPhone()" v-else>
    <Button class="roommate-button"
            :size="'medium'" :theme="'solid-grey'"
            :text="$t('call-us')"
            v-if="!isPhoneShown"/>
    <div class="phone-box my-3 p-3 text-center" v-else>{{ phone }}</div>
  </div>
</div>
</template>

