<template>

  <BedIcon v-if="icon === 'bed'" />
  <CoffeeTable v-if="icon === 'coffee-table'" />
  <DecorativeLightingIcon v-if="icon === 'decorative-lighting'" />
  <DishwasherIcon v-if="icon === 'dishwasher'" />
  <FridgeIcon v-if="icon === 'fridge'" />
  <KitchenTableIcon v-if="icon === 'kitchen-table'" />
  <MicrowaveIcon v-if="icon === 'microwave-oven'" />
  <OvenIcon v-if="icon === 'cooking-stove'" />
  <SofaIcon v-if="icon === 'sofa'" />
  <SoundIcon v-if="icon === 'audio-system'" />
  <TvIcon v-if="icon === 'television'" />
  <WashingMachineIcon v-if="icon === 'laundry'" />
  <TVCabinetIcon v-if="icon === 'TV-cabinet'" />
  <DryerIcon v-if="icon === 'dryer'" />
  <TableIcon v-if="icon === 'table'" />
  <ShoeCabinetIcon v-if="icon === 'shoe-cabinet'" />
  <ToasterIcon v-if="icon === 'toast'" />
  <BoilerIcon v-if="icon === 'boiler'" />
  <CoffeeMachineIcon v-if="icon === 'coffee-machine'" />
  <ChairsIcon v-if="icon === 'chairs'" />
  <WardrobeIcon v-if="icon === 'warderobe'" />


</template>
<script>
import BedIcon from '@/components/Common/Icons/BedIcon';
import WardrobeIcon from '@/components/Common/Icons/WardrobeIcon';
import CoffeeMachineIcon from '@/components/Common/Icons/CoffeeMachineIcon';
import ChairsIcon from '@/components/Common/Icons/ChairsIcon';
import ShoeCabinetIcon from '@/components/Common/Icons/ShoeCabinetIcon';
import DryerIcon from '@/components/Common/Icons/DryerIcon';
import CoffeeTable from '@/components/Common/Icons/CoffeeTable';
import DecorativeLightingIcon from '@/components/Common/Icons/DecorativeLightingIcon';
import DishwasherIcon from '@/components/Common/Icons/DishwasherIcon';
import FridgeIcon from '@/components/Common/Icons/FridgeIcon';
import KitchenTableIcon from '@/components/Common/Icons/KitchenTableIcon';
import MicrowaveIcon from '@/components/Common/Icons/MicrowaveIcon';
import OvenIcon from '@/components/Common/Icons/OvenIcon';
import SofaIcon from '@/components/Common/Icons/SofaIcon';
import SoundIcon from '@/components/Common/Icons/SoundIcon';
import TableIcon from '@/components/Common/Icons/TableIcon';
import TvIcon from '@/components/Common/Icons/TvIcon';
import WashingMachineIcon from '@/components/Common/Icons/WashingMachineIcon';
import TVCabinetIcon from '@/components/Common/Icons/TVCabinetIcon';
import ToasterIcon from '@/components/Common/Icons/ToasterIcon';
import BoilerIcon from '@/components/Common/Icons/BoilerIcon';

export default {
  name: 'AmenityIcon',

  components: {
    BedIcon,
    CoffeeTable,
    DecorativeLightingIcon,
    DishwasherIcon,
    FridgeIcon,
    KitchenTableIcon,
    MicrowaveIcon,
    OvenIcon,
    SofaIcon,
    SoundIcon,
    TvIcon,
    WashingMachineIcon,
    TVCabinetIcon,
    DryerIcon,
    TableIcon,
    ShoeCabinetIcon,
    ToasterIcon,
    BoilerIcon,
    CoffeeMachineIcon,
    ChairsIcon,
    WardrobeIcon
  },
  props: {
    icon: {
      type: String,
      default: '',
    }
  }
}
</script>
<style lang="scss" scoped>
</style>