<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<style lang="scss" >
.single-property-marketplace-carousel-wrapper {
  .carousel__next, .carousel__prev {
    top: 150px;
  }
}
</style>
<template>
    <div class="single-property-marketplace-carousel single-property-marketplace-carousel-wrapper">
        <div class="location-main-title">{{ $t('furnish-this-property') }}</div>
        <div class="property-marketplace-nav d-flex slider">
            <div class="me-2 slider__item" v-for="amenity in missingAmenities">
                <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: amenity.categoryId}}">
                    {{ $t(amenity.name) }}
                </router-link>
            </div>
        </div>
        <div class="marketplace-carousel">
            <Carousel v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="(product, product_index) in products" :key="product_index">
                    <div class="slider_item">
                        <MarketplaceAd
                            :id="product.id"
                            :labelnew="true /* todo:! */"
                            :labeldiscount="true"
                            :title="product.title"
                            :category="product.categories.length > 0 ? $t(product.categories[0].title) : ''"
                            :regularamount="product.price"
                            :discountedamount="product.promoPrice"
                            :image="product.mainImage !== null ? product.mainImage.src : ''"
                        />
                    </div>
                </Slide>
              <template #addons>
                <Navigation>
                  <template #next>
                    <div class="buttons-left-right button-right d-none d-md-block">
                      <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1747_9026)">
                          <path d="M1 7L17 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                          <path d="M11 13L17 7L11 0.999999" stroke="#434D56" stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1747_9026 ">
                            <rect width="18" height="14" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </template>
                  <template #prev>
                    <div class=" buttons-left-right button-left d-none d-md-block">
                      <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1747_16541)">
                          <path d="M17 7L1 7" stroke="#434D56" stroke-width="1.5" stroke-linecap="round"/>
                          <path d="M7 1L1 7L7 13" stroke="#434D56" stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1747_16541">
                            <rect width="18" height="14" fill="white"
                                  transform="translate(18 14) rotate(180)"/>
                          </clipPath>
                        </defs>
                      </svg>

                    </div>
                  </template>
                </Navigation>
              </template>
            </Carousel>
        </div>
    </div>
</template>

