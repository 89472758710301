<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<style lang="scss">
#lightbox {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-content {
    border-radius: 5px 0 5px 5px;
  }
  .modal-body {
    padding: 10px;
  }
  .close-button {
    position: static;
    background: #fff;
    padding: 10px;
    border-radius: 0 5px 5px 0;
    @media  only screen  and (min-width: 600px) {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
    }
  }
}
</style>
<template>
  <div id="lightbox">
    <Modal :name="'lightbox'" :is-open="$store.state.utils.modals.lightbox" :size="'large'">
      <div v-bind:class="{'horizontal': !isVertical}">
        <img ref="image"
             v-bind:src="'/storage/' + imageList[index]"
             v-bind:style="{'max-width': '100%', 'width': !isVertical ? '100%' : 'auto', 'height': isVertical ? '70vh' : 'auto'}"
             alt=""
        >
        <div class="controls">
          <div class="next" @click="nextImage()">&rarr;</div>
          <div class="prev" @click="prevImage()">&larr;</div>
        </div>
      </div>
    </Modal>
  </div>
</template>